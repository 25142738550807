import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { theme } from '@styles';
import PlayIcon from '@assets/images/play-icon.svg';
import ExternalLinkIcon from '@assets/icons/ExternalLinkIcon';
import Button from '@components/Button';
import { capitalizeFirstLetter } from '@utils';
import CustomText from '@components/CustomText';
import Box from '@components/Box';
import { IGroup } from '@appTypes';

const { colors, breakpoints } = theme;

type Props = {
  group: IGroup;
  index: any;
  shouldPause: boolean;
  onVideoPlay: any;
  fullWidth?: boolean;
  withDescription?: boolean;
};

const GroupCard: React.FC<Props> = ({ group, index, shouldPause, onVideoPlay, fullWidth }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showPlayIcon, setShowPlayIcon] = useState(true);

  useEffect(() => {
    if (shouldPause) {
      if (!videoRef?.current?.paused) {
        videoRef?.current?.pause();
        setShowPlayIcon(true);
      }
    }
  }, [shouldPause]);

  const handleVideoClick = () => {
    if (videoRef?.current?.paused) {
      videoRef?.current.play();
      setShowPlayIcon(false);
    } else {
      setShowPlayIcon(true);
      videoRef?.current?.pause();
    }
  };

  return (
    <Container key={index} fullWidth={fullWidth}>
      <GroupCardHeader fullWidth={fullWidth}>
        {group.preview && (
          <VideoWrapper
            data-index={index}
            onClick={(e) => {
              handleVideoClick();
              onVideoPlay(e);
            }}
          >
            {showPlayIcon && (
              <GroupPlayImage
                className='play-icon'
                style={{
                  marginRight: '10px',
                }}
                src={PlayIcon}
                alt={'Play icon'}
              />
            )}
            <GroupVideo
              ref={videoRef}
              poster={group.preview.poster}
              src={group.preview.url}
              playsInline
              preload={'none'}
              loop
            />
          </VideoWrapper>
        )}
      </GroupCardHeader>
      <GroupBody>
        <GroupNameContainer>
          <GroupName>{group.name}</GroupName>
        </GroupNameContainer>
        <Box mb={2}>
          <CustomText fontSize={16}>{capitalizeFirstLetter(group.kind)}</CustomText>
          <Box mt={-1}>
            <CustomText color={colors.grannySmith} fontSize={16}>
              {group.rounds} rounds, {` `}
              {group.exerciseBreakDuration > 0 && `${group.exerciseBreakDuration / 1000}s rest between exercises, `}
              {group.roundBreakDuration / 1000}s rest between rounds
            </CustomText>
          </Box>
        </Box>

        <CustomText fontSize={16}>Exercise details</CustomText>
        <Box mt={-1}>
          {group.exerciseConfig.timeBasedConfig ? (
            <CustomText color={colors.grannySmith} fontSize={16}>
              {group.exerciseConfig.timeBasedConfig.duration / 1000}s, {` `}
              {group.exerciseConfig.timeBasedConfig.pace} pace
            </CustomText>
          ) : (
            <CustomText color={colors.grannySmith} fontSize={16}>
              {group.exerciseConfig.repsBasedConfig?.reps} reps, {` `}
              {group.exerciseConfig.repsBasedConfig?.weightLevel} weight
            </CustomText>
          )}
        </Box>
      </GroupBody>
      <GroupFooter>
        <ButtonWrapper fullWidth={fullWidth}>
          <a href={`/group?groupId=${group.id}`} target='_blank' rel={'noreferrer'}>
            <Button
              fontSize={14}
              icon={<ExternalLinkIcon width={24} height={24} color={colors.outerSpace} />}
              label='Open Group'
              type={'tertiary'}
            />
          </a>
        </ButtonWrapper>
      </GroupFooter>
    </Container>
  );
};

export default GroupCard;

const ButtonWrapper = styled.div<{ fullWidth?: boolean }>`
  height: 50px;
  width: ${({ fullWidth }) => (fullWidth ? '183px' : '100%')};
  margin: 0px 24px 24px 24px;
  a {
    text-decoration: none;
  }
`;

const GroupFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const GroupNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GroupName = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  color: ${colors.outerSpace};
  width: 80%;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 20px;
  }
`;

const GroupVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const GroupCardHeader = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 230px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    ${({ fullWidth }) =>
      fullWidth
        ? `height: 618px;
  }`
        : `height: 230px`};
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    .play-icon {
      opacity: 0.8;
    }
  }
`;

const GroupPlayImage = styled.img`
  position: absolute;
  z-index: 3;
`;

const GroupBody = styled.div`
  padding: 24px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  flex: 1;
`;

const Container = styled.div<{ fullWidth?: boolean }>`
  margin: 0 auto;
  align-items: center;
  border: 1px solid ${colors.mystic};
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  max-width: 327px;
`;
